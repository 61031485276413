(function ($) {
  Drupal.behaviors.basicFormatterConcernV1 = {
    attach: function (context) {
      var tabIndex = 1;
      var tabLinks = $('.basic-formatter-concerns__nav');

      tabLinks.append('<ul class="basic-formatter-concerns__tab-links allcaps"></ul>');
      tabLinks.append('<select class="basic-formatter-concerns__tab-select"></select>');

      $('.basic-formatter-concerns__item').each(function () {
        var currentItem = $(this);
        var tabText = currentItem.find('.concern-name').first().text();
        var tabID = 'tab' + tabIndex;

        currentItem.attr('id', tabID);
        var tabTitle = $('<li>' + tabText + '</li>');

        $('.concern-name').hide();
        tabTitle.once().on('click', function () {
          tabLinks.find('li').removeClass('active');
          $(this).addClass('active');
          $('.basic-formatter-concerns__item').hide();
          currentItem.show();
          currentItem.find('.js-product-carousel').slick('getSlick').refresh();
        });
        tabLinks.find('ul').append(tabTitle);

        var tabOption = $('<option value="' + tabID + '">' + tabText + '</option>');

        tabLinks.find('select').append(tabOption);
        tabLinks
          .find('select')
          .once()
          .on('change', function () {
            var selectedTabID = $(this).find(':selected').val();

            $('.basic-formatter-concerns__item').hide();
            $('#' + selectedTabID).show();
            $('#' + selectedTabID)
              .find('.js-product-carousel')
              .slick('getSlick')
              .refresh();
          });

        currentItem.hide();
        tabIndex++;
      });

      $('.basic-formatter-concerns__item').first().show();
      $('.basic-formatter-concerns__tab-links li').first().addClass('active');
    }
  };
})(jQuery);
